import { createMuiTheme } from '@material-ui/core/styles';
const theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitFontSmoothing: 'auto',
        },
        body: {
          backgroundColor: `var(--yellow)`,
        },
        '#root': {
          overflow: 'auto',
        },
      },
    },
  },
});
export default theme;
