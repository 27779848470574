import React from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

const Home = () => {
  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/Users" onClick={(event: any) => event.preventDefault()}>
          Users
        </Link>
        <Link color="inherit" href="/Users/lasimonne" onClick={(event: any) => event.preventDefault()}>
          lasimonne
        </Link>
        <Typography color="textPrimary">gosolly</Typography>
      </Breadcrumbs>
    </div>
  )
}

export default Home;
